//dev
const URL_PATH = 'https://devadmin.gstore.in/'

//live
// const URL_PATH = 'https://admin.gstore.in/'

export const BASE_AUTH_URL = `${URL_PATH}user/`;

const BASE_ATTACHMENT_URL = `${URL_PATH}file/v1/attachment`;

const BASE_CONCIERGE_URL = '3.7.204.122/catalog/v1/admin/tenant/';

export const BASE_E_COMMERCE_URL = `${URL_PATH}catalog/v1/admin/tenant/`;

export const LOGIN = `${BASE_AUTH_URL}oauth/token`;

export const UPLOAD_URL = `${BASE_ATTACHMENT_URL}`;

export const getUploadApiUrl = () => {
    return BASE_ATTACHMENT_URL
}

export const getCategoriesApiUrl = (tenantId) => {
    return `${BASE_E_COMMERCE_URL}${tenantId}/category`
}

export const getCategoryApiUrl = (tenantId, categoryId) => {
    return `${BASE_E_COMMERCE_URL}${tenantId}/category/${categoryId}`
}

export const getSaveCategoryApiUrl = (tenantId) => {
    return `${BASE_E_COMMERCE_URL}${tenantId}/category`
}

export const getBrandsApiUrl = (tenantId) => {
    return `${BASE_E_COMMERCE_URL}${tenantId}/brand`
}

export const getBrandApiUrl = (tenantId, brandId) => {
    return `${BASE_E_COMMERCE_URL}${tenantId}/brand/${brandId}`
}

export const getSaveBrandApiUrl = (tenantId) => {
    return `${BASE_E_COMMERCE_URL}${tenantId}/brand`
}

export const getBannersApiUrl = (tenantId) => {
    return `${BASE_E_COMMERCE_URL}${tenantId}/banner`
}

export const getBannerApiUrl = (tenantId, bannerId) => {
    return `${BASE_E_COMMERCE_URL}${tenantId}/banner/${bannerId}`
}

export const getSaveBannerApiUrl = (tenantId) => {
    return `${BASE_E_COMMERCE_URL}${tenantId}/banner`
}

export const getProductsApiUrl = (tenantId) => {
    return `${BASE_E_COMMERCE_URL}${tenantId}/product`
}

export const getProductApiUrl = (tenantId, productId) => {
    return `${BASE_E_COMMERCE_URL}${tenantId}/product/${productId}`
}

export const getProductDetailApiUrl = (tenantId, productId) => {
    return `${BASE_E_COMMERCE_URL}${tenantId}/product/all/${productId}`
}

export const getSaveProductApiUrl = (tenantId) => {
    return `${BASE_E_COMMERCE_URL}${tenantId}/product`
}

export const getSaveColorByProductApiUrl = (tenantId, productId) => {
    return `${BASE_E_COMMERCE_URL}${tenantId}/product/${productId}/product-color`
}

export const getColorsByProductApiUrl = (tenantId, productId) => {
    return `${BASE_E_COMMERCE_URL}${tenantId}/product/${productId}/product-color`
}

export const getColorByProductApiUrl = (tenantId, productId,colorId) => {
    return `${BASE_E_COMMERCE_URL}${tenantId}/product/${productId}/product-color/${colorId}`
}

export const getSaveImageByColorProductApiUrl = (tenantId, productId,colorId) => {
    return  `${BASE_E_COMMERCE_URL}${tenantId}/product/${productId}/product-color/${colorId}/product-image`
}

export const getImagesbyColorProductApiUrl = (tenantId, productId,colorId) => {
    return  `${BASE_E_COMMERCE_URL}${tenantId}/product/${productId}/product-color/${colorId}/product-image`
}

export const getImageBycolorProductApiUrl = (tenantId, productId,colorId,pImageId) => {
    return `${BASE_E_COMMERCE_URL}${tenantId}/product/${productId}/product-color/${colorId}/product-image/${pImageId}`
}

export const getSaveProductSkutypebyColorProductApiUrl = (tenantId, productId,colorId) => {
    return  `${BASE_E_COMMERCE_URL}${tenantId}/product/${productId}/product-sku-type`
}

export const getProductSkutypesbyColorProductApiUrl = (tenantId, productId,colorId) => {
    return  `${BASE_E_COMMERCE_URL}${tenantId}/product/${productId}/product-color/${colorId}/product-sku-type`
}

export const getProductSkutypeBycolorProductApiUrl = (tenantId, productId,pSkuTypeId) => {
    return `${BASE_E_COMMERCE_URL}${tenantId}/product/${productId}/product-sku-type/${pSkuTypeId}`
}

export const getSaveProductSkubyColorProductApiUrl = (tenantId, productId,colorId,pSkuTypeId) => {
    return  `${BASE_E_COMMERCE_URL}${tenantId}/product/${productId}/product-color/${colorId}/product-sku`
}

export const getProductSkusbyColorProductApiUrl = (tenantId, productId,colorId,pSkuTypeId) => {
    return  `${BASE_E_COMMERCE_URL}${tenantId}/product/${productId}/product-color/${colorId}/product-sku-type/${pSkuTypeId}/product-sku`
}

export const getProductSkuBycolorProductApiUrl = (tenantId, productId,colorId,pSkuId) => {
    return `${BASE_E_COMMERCE_URL}${tenantId}/product/${productId}/product-color/${colorId}/product-sku/${pSkuId}`
}

export const getSaveProductPricebyColorProductApiUrl = (tenantId, productId,colorId,pSkuTypeId,pSkuId) => {
    return  `${BASE_E_COMMERCE_URL}${tenantId}/product/${productId}/product-color/${colorId}/product-sku-type/${pSkuTypeId}/product-sku/${pSkuId}/price`
}

export const getProductPricesbyColorProductApiUrl = (tenantId, productId,colorId,pSkuTypeId,pSkuId) => {
    return  `${BASE_E_COMMERCE_URL}${tenantId}/product/${productId}/product-color/${colorId}/product-sku-type/${pSkuTypeId}/product-sku/${pSkuId}/price`
}

export const getProductPriceBycolorProductApiUrl = (tenantId, productId,colorId,pSkuTypeId,pSkuId,priceId) => {
    return `${BASE_E_COMMERCE_URL}${tenantId}/product/${productId}/product-color/${colorId}/product-sku-type/${pSkuTypeId}/product-sku/${pSkuId}/price/${priceId}`
}

export const getServicesApiUrl = (tenantId) => {
    return `${BASE_CONCIERGE_URL}${tenantId}/service`
}

export const getSaveServiceApiUrl = (tenantId) => {
    return `${BASE_CONCIERGE_URL}${tenantId}/service`
}

export const getAttributesbyProductApiUrl = (tenantId, productId) => {
    return `${BASE_E_COMMERCE_URL}${tenantId}/product/${productId}/product-attribute`
}

export const getAttributebyProductApiUrl = (tenantId, productId, attributeId) => {
    return `${BASE_E_COMMERCE_URL}${tenantId}/product/${productId}/product-attribute/${attributeId}`
}

export const SaveAttributebyProductApiUrl = (tenantId, productId) => {
    return `${BASE_E_COMMERCE_URL}${tenantId}/product/${productId}/product-attribute`
}

export const getOrderHistory = (tenantId) => {
    return `${URL_PATH}order/v1/admin/tenant/${tenantId}/order`
}

export const getOrderHistorybyId = (customerId) => {
    return `${URL_PATH}order/v1/order/${customerId}`
}

export const updateOrderbyId = (orderId,status) => {
    return `${URL_PATH}order/v1/order/order-status/${orderId}/${status}`
}

export const deleteOrderbyId = (orderId) => {
    return `${URL_PATH}order/v1/order/${orderId}`
}


export const getCartsByTenantId = (tenantId) => {
    return `${URL_PATH}checkout/v1/admin/tenant/${tenantId}/cart`
}

export const getCartByTenantIdandCartId  = (tenantId, cartId) => {
    return `${URL_PATH}checkout/v1/admin/tenant/${tenantId}/cart/${cartId}`
}

export const getSkutypesByColorId = (tenantId,productId,colorId) => {
    return `${BASE_E_COMMERCE_URL}${tenantId}/product/${productId}/product-sku-type/color/${colorId}`
}

export const saveCartByTenantId = (tenantId) => {
    return `${URL_PATH}checkout/v1/admin/tenant/${tenantId}/cart`
}

export const getSkuBySkuTypeId = (tenantId,productId,colorId,skutype) => {
    return `${BASE_E_COMMERCE_URL}${tenantId}/product/${productId}/product-color/${colorId}/product-sku/skuType/${skutype}`
}

export const saveTopBrandApiUrl = (tenantId) => {
    return `${BASE_E_COMMERCE_URL}${tenantId}/top-brands`
}

export const getTopBrandsByTenantIdApiUrl = (tenantId) =>{
    return `${BASE_E_COMMERCE_URL}${tenantId}/top-brands/tenant`
}

export const deleteTopBrandApiUrl = (tenantId,id) =>{
    return `${BASE_E_COMMERCE_URL}${tenantId}/top-brands/${id}`
}

export const updateTopBrandApiUrl = (tenantId) =>{
    return `${BASE_E_COMMERCE_URL}${tenantId}/top-brands`
}

export const searchProducts = (tenantId, name) =>{
    return `${URL_PATH}catalog/v1/admin/tenant/${tenantId}/product/search?query=${name}`
}

export const saveRelatedProductsUrl =(tenantId) =>{
    return `${URL_PATH}catalog/v1/admin/tenant/${tenantId}/related-product`
}

export const getRelatedProductsUrl =(tenantId,id) =>{
    return `${URL_PATH}catalog/v1/admin/tenant/${tenantId}/related-product/get-product/${id}`
}

export const deleteRelatedProductsUrl =(tenantId, id) =>{
    return `${URL_PATH}catalog/v1/admin/tenant/${tenantId}/related-product/${id}`
}

export const saveDealUrl = (tenantId) =>{
    return `${URL_PATH}catalog/v1/admin/tenant/${tenantId}/deals`
}

export const getDealsUrl = (tenantId) =>{
    return `${URL_PATH}catalog/v1/admin/tenant/${tenantId}/deals/all`
}

export const getDealUrl = (tenantId,id) =>{
    return `${URL_PATH}catalog/v1/admin/tenant/${tenantId}/deals/${id}`
}

export const saveDealProductUrl = (tenantId) =>{
    return `${URL_PATH}catalog/v1/admin/tenant/${tenantId}/deal-products`
}

export const getDealProductsByDealUrl = (tenantId, id) =>{
    return `${URL_PATH}catalog/v1/admin/tenant/${tenantId}/deal-products/by-deal/${id}`
}

export const getDealProductUrl = (tenantId, id) =>{
    return `${URL_PATH}catalog/v1/admin/tenant/${tenantId}/deal-products/${id}`
}

export const getProductById = (tenantId,id) =>{
    return `${URL_PATH}catalog/v1/admin/tenant/${tenantId}/product/all/${id}`
}

export const saveStockUrl = (tenantId) =>{
    return `${URL_PATH}stock/v1/admin/tenant/${tenantId}/stock`
}

export const getStocksUrl = (tenantId) =>{
    return `${URL_PATH}stock/v1/admin/tenant/${tenantId}/stock`
}

export const getStockUrl = (tenantId,id) =>{
    return `${URL_PATH}stock/v1/admin/tenant/${tenantId}/stock/product/${id}`
}
