
import React from 'react'
import  useFileUploader  from '../../hooks/useFileUploader'
import HDiv from '../containers/hdiv'
import VDiv from '../containers/vdiv'
import AddButton from './add-button'
import ProcessLoader from '../general/process-loader'
import { handleConfirmPopup } from '../general/confirmDialog'

const ImageSelector = ({label, className, onFileSelect,required,enabled = true, showPreview = true, name, multiple = false, showProgress = false, value, update, index, handleImageEditClick}) => {

    const {fileRef, openFileSelector, handleFileSelect, files, openFile, selectedFile, onFileClick, closeFile, removeFile, show, handleImageDeleteClick} = useFileUploader({onFileSelect,index,multiple,value,update, enabled})
    return(
        <VDiv className={` file d-flex gap-2 file-pre ${className ? className : ''}  ${!enabled ? "opacity-50" : 'opacity'}`}>
            {showPreview && files && multiple ? 
            <HDiv className="mb-3 relative w-auto ">
                {/* eslint-disable-next-line */}
                {files.map((file,index) => {
                if(file.deleted === false && file.id){
                    if(file.id){
                        return  <VDiv  className='file-preview ' key={file.name} >
                        <VDiv className="file-img" onClick={(event) => onFileClick(event, {name: file.name,image: file.imageUrl})} style={{backgroundImage: `url(${file.imageUrl})`}}>
                        </VDiv>
                      { enabled && <div className='action-img'>
                            {/* <img className='edit' src="./assets/images/icons/edit-btn.png" alt={'Edit'} onClick={()=>handleImageEditClick(file)}/> */}
                            <img className='close text-truncate' src="./assets/images/icons/close-btn.png" alt="close" onClick={(event) =>handleConfirmPopup(event, ()=>handleImageDeleteClick(file,index),`image ${file.name}`)} />
                        </div>}
                    </VDiv>
                    }
                    else{
                        return null
                    }
                }
                if(!file.id){
                    return  <VDiv  className='file-preview ' key={file.name} >
                    <VDiv className="file-img" onClick={(event) => onFileClick(event, file)} style={{backgroundImage: `url(${file.image})`}}>
                    </VDiv>
                    <div className='action-img'>
                        <img className='close' src="./assets/images/icons/close-btn.png" alt="close" onClick={() => removeFile(index)} />
                    </div>
                </VDiv>  
                }  else{
                    return null
                }
                })}
                {showProgress &&
                    <ProcessLoader />
                }
            </HDiv> : null}

          {files.length < 6 &&  <HDiv className="image-selector flex-column" onClick={openFileSelector}>
                <AddButton />
                {!update ?<input className='fileSelector w-100' type="file" name={name} onChange={(event) => handleFileSelect(event)} ref={fileRef} multiple={multiple} required={required} disabled={!enabled}  /> :
                <input className='fileSelector w-100' type="file" name={name} onChange={(event) => handleFileSelect(event)} ref={fileRef} multiple={multiple} disabled={!enabled}  />}

            </HDiv>}

            
            {showPreview && files && !multiple && files.map((file) => 
                <VDiv onClick={(event) => onFileClick(event, file)}  className="cover-preview mt-3 relative" key={file.name} style={file.type === 'video/mp4' ? {}: {backgroundImage: `url(${file.image})`}}>
                    { file.type === 'video/mp4' &&
                    <video className='video' width="100%" height={"100%"} controls>
                        <source src={file.image} type="video/mp4" />
                    </video>
                    }
                    {showProgress &&
                        <ProcessLoader />
                    }
                </VDiv>
            )}
            {openFile  ?
              (<VDiv className="show-file-container">
                <img className='file' alt={selectedFile.name} src={selectedFile.image} />
                <img className='close-btn' src='/assets/images/icons/icon-close-white.png' alt='close' onClick={(event) => closeFile(event)} />
             </VDiv>)
               : null
                   
            }
            {update && show && value !==null && !multiple ?(<div className='preview-img w-100 '>
                    <img className='file' alt={"preview-img"} src={value} width="100%" height="100%" />
               </div>): null}

        </VDiv>
    )
}

export default ImageSelector