import React from 'react'
import HDiv from '../containers/hdiv';

export const handleConfirmPopup = (event, fn, des) => {
    event.preventDefault();
    document.getElementById("deletePoupbutton").click()
    document.getElementById("deletePopupdes").innerHTML = des ? ` ${des}` : ""
    document.getElementById("confirm").onclick = function () {
        fn()
        document.getElementById("cancel").click()
    }
}

export default function PopUp() {
    return (
        <>
            <button className='d-none' id="deletePoupbutton" data-bs-target="#exampleModalToggle" data-bs-toggle="modal"></button>
            <div class="modal fade fade-popup shadow-lg" id="exampleModalToggle" aria-labelledby="exampleModalToggleLabel" tabindex="-1" data-bs-backdrop="static" aria-hidden="true" style={{zIndex:5000}} >
                <div class="modal-dialog modal-dialog-centered  ">
                    <div class="modal-content">
                        <div class="modal-body">
                            <HDiv className={"mb-1 justify-content-between"}>
                                <h1 class="modal-title fs-5" id="staticBackdropLabel">Are You Sure ?</h1>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </HDiv>
                            <p  className='mb-3 text-wrap fw-bold' >Delete <span id="deletePopupdes"></span></p>
                            <HDiv className={"justify-content-end gap-3"}>
                                <button class="btn btn-secondary" id='cancel' data-bs-dismiss="modal">Cancel</button>
                                <button class="btn btn-danger" id='confirm'>Confirm</button>
                            </HDiv>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}


