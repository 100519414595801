import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import HDiv from '../containers/hdiv';
import InputGroup from '../inputs/input-group';
import SelectGroup from '../inputs/select-group';
import AddButton from '../inputs/add-button';
import ImageSelector from '../inputs/image-selector';
import TextArea from '../inputs/textarea';
import CancelIcon from '@mui/icons-material/Cancel';
import ProcessLoader from '../general/process-loader';
import DeleteButton from '../inputs/delete-button';
import { handleConfirmPopup } from '../general/confirmDialog';


export default function UpdateProductPopup({ hook, category, brand, update, formData, setShowForm }) {

    const { handleInputChange, onFileSelect, data, uploadInProgress, discountType, colorType, ProductColor, handleDelete, handleAdd, handleSelectedChange, handleProductInfo, handleAddAttribute, 
        handleSizeChange, handleSizeDelete, handleColor, colorId, handleSize, handleDeleteColor, handleDeleteSize, apiInProgress, productId, handleAddSize, handleAttributeChange, handleAttributeDelete, handleSaveAttribute, handleDeleteAttribute, handleDisableInput, handleColorDisabledButton,handleSizeDisabledBtn,handleProductDisabled } = hook({ update, formData, setShowForm });
    return (
        <>
            <div class="tab-content">
           
                <Box component={"form"} onSubmit={(event) => handleProductInfo(event)} className='tab-pane fade show active' id='headerContent1'>
                    <HDiv className="flex-wrap gap-3 align-items-center pt-2" >
                        <InputGroup value={data.name} className="col-xl-3 col-12 flex-grow-1" onChange={handleInputChange} enabled={data.btnDisabled} label="Product Name*" name="name" type="text" placeHolder="Enter name" required />
                        <SelectGroup label="Brand*" className="col-xl-3 col-12 flex-grow-1" placeHolder="Brand" value={data.brand} list={brand} enabled={data.btnDisabled} onChange={handleInputChange} name="brand" required />
                        <SelectGroup label="Categories*" className="col-xl-3 col-12 flex-grow-1" placeHolder="Categories" value={data.category} enabled={data.btnDisabled} list={category} onChange={handleInputChange} name="category" required={true} />
                        <TextArea value={data.description} className="col-5 col-12 flex-grow-1" onChange={handleInputChange} enabled={data.btnDisabled} label="Description*" name="description" type="text" placeHolder="Enter description" required />
                        <HDiv className={"gap-3"}>
                            <label className='d-flex align-items-center gap-2'>
                                <input type='checkbox' name='replacement' onChange={handleInputChange} disabled={!data.btnDisabled} checked={data.replacement} value={data.replacement} /> Replacement
                            </label>
                            {data.replacement && <InputGroup value={data.replacementDays} className="col-xl-3 col-12" onChange={handleInputChange} enabled={data.btnDisabled} label="Replacement Days" name="replacementDays" type="text" placeHolder="Enter Replacement days" required />}
                            <label className='d-flex align-items-center gap-2'>
                                <input type='checkbox' name='warranty' onChange={handleInputChange} disabled={!data.btnDisabled} checked={data.warranty} value={data.warranty} /> Warranty
                            </label>
                            {data.warranty && <InputGroup value={data.warrantyPeriod} className="col-xl-3 col-12" onChange={handleInputChange} enabled={data.btnDisabled} label="Warranty period" name="warrantyPeriod" type="text" placeHolder="Enter warranty period " required />}

                        </HDiv>
                    </HDiv>
                    <HDiv className={"justify-content-end mt-3"}>
                        {update && <Button type="button" disabled={data.btnDisabled} onClick={()=>handleProductDisabled()}>Edit</Button>}
                        <Button type="Submit" disabled={!data.btnDisabled}>
                            {update ? "Update" : "Save"}
                        </Button>
                    </HDiv>

                </Box>
                <div className='tab-pane fade' id="headerContent2">
                    <ul class="nav nav-tabs align-items-center gap-2">
                        {ProductColor.length === 0 && <li class="nav-item nav-color d-none align-items-center  ">
                            <a class="nav-link gj-colorTab active" id={`colorTab0`} data-bs-toggle="tab" href={`#colorContent0`}>Hello</a>
                        </li>}
                        {ProductColor && ProductColor.length > 0 && ProductColor.map((color, index) =>
                            <li class="nav-item nav-color">
                                <a class={`nav-link d-flex gap-3 gj-colorTab ${index === 0 ? "active" : ""}`} id={`colorTab${index + 1}`} data-bs-toggle="tab" href={`#colorContent${index + 1}`}>{color.name !== "" ? color.name : "Color"}<CancelIcon sx={{ backgroundColor: "white", borderRadius: "20px" }} onClick={(event) => { color.colorId !== "" ? handleConfirmPopup(event, ()=>handleDeleteColor(color.colorId, index), color.name !== "" ? color.name : "Color" ) : handleConfirmPopup(event, ()=>handleDelete(index), color.name !== "" ? color.name : "Color") }} /></a>
                            </li>
                        )}
                        {ProductColor.length > 0 && <li class="nav-item">
                            <AddButton onClick={() => handleAdd()} />
                        </li>}
                    </ul>
                    <div class="tab-content">
                        {ProductColor.length === 0 && <div class="tab-pane fade d-none gj-colorContent show active" id={`colorContent0`}></div>}
                        {ProductColor && ProductColor.length > 0 && ProductColor.map((items, index) =>
                            <div class={`tab-pane fade gj-colorContent ${index === 0 ? "show active" : ""}`} id={`colorContent${index + 1}`}>
                                <Box component={"form"} onSubmit={(event) => handleColor(event, index)}>
                                    <HDiv className="flex-wrap gap-3 align-items-center pt-2" >
                                        <InputGroup value={items.name} className={"col-xl-3 col-12 flex-grow-1"}  onChange={(event) => { handleSelectedChange(event, index) }} enabled={items.btnDisabled} label="Color Name*" name="name" type="text" placeHolder="Enter name" required />
                                        <SelectGroup value={items.type} className={"col-xl-3 col-12 flex-grow-1"} onChange={(event) => handleSelectedChange(event, index)} enabled={items.btnDisabled} label="Color Type*" list={colorType} name="type" placeHolder="Color Type" required />
                                        <div className={" edc-input-group position-relative col-xl-3 col-12 flex-grow-1"} >
                                            <label className="form-label">Color Value*</label>
                                            <HDiv>
                                                <input className="form-control text-input w-75" name="value" disabled={!items.btnDisabled} placeholder="Pick color" onChange={(event) => handleSelectedChange(event, index)} type={"text"} value={items.value} required />
                                                <input type="color" name="value" disabled={!items.btnDisabled} onChange={(event) => handleSelectedChange(event, index)} value={items.value} class="form-control text-input color-select" />
                                            </HDiv>
                                        </div>
                                        <HDiv className={"mb-3"}>
                                            <p className='fw-bold'>Add images for {items.name} color </p>
                                        </HDiv>
                                        <Box width={"100%"} sx={{ overflowX: "scroll" }}>
                                            <ImageSelector onFileSelect={onFileSelect} showProgress={uploadInProgress} multiple value={items.productImages !== null ? items.productImages : []} update={update} index={index} required enabled={items.btnDisabled} />
                                        </Box>
                                    </HDiv>
                                    <HDiv className={"justify-content-end"}>
                                    { items.colorId !== "" &&  <Button type="button" disabled={items.btnDisabled} onClick={()=>handleColorDisabledButton(index)}>Edit</Button>}
                                        <Button type="submit" disabled={!items.btnDisabled}>
                                            {items.colorId !== "" ? "Update" : "Save"}
                                        </Button>
                                    </HDiv>
                                </Box>

                                <ul class="nav nav-tabs align-items-center gap-2">
                                    {items.sizes.length === 0 && <li class="nav-item nav-color d-none align-items-center  ">
                                        <a class={`nav-link gj-sizeTab${index} active`} id={`sizeTab0`} data-bs-toggle="tab" href={`#colorContent0`}>Hello</a>
                                    </li>}
                                    {items.sizes && items.sizes.length > 0 && items.sizes.map((size, sizeIndex) =>{

                                        
                                        return (
                                            <li class="nav-item nav-color d-flex align-items-center">
                                            <a class={`nav-link d-flex gap-3 gj-sizeTab${index} ${sizeIndex === 0 ? "active" : ""}`} id={`sizeTab${index}${sizeIndex + 1}`} data-bs-toggle="tab" href={`#sizeContent${index}${sizeIndex + 1}`}>{size.skuTypeName !== "" ? size.skuTypeName : "Size"}<CancelIcon sx={{ backgroundColor: "white", borderRadius: "20px" }} onClick={(event) => { size.skuTypeId !== "" ? handleConfirmPopup(event, ()=>handleDeleteSize(index, sizeIndex, size.skuTypeId,size.skuId,size.priceId),size.skuTypeName !== "" ? size.skuTypeName : "Size") : handleConfirmPopup(event,()=>handleSizeDelete(index, sizeIndex),size.skuTypeName !== "" ? size.skuTypeName : "Size") }} /></a>
                                        </li>
                                        )
                                    }
                                      
                                    )}
                                    {items.sizes && items.sizes.length > 0 && <li class="nav-item">
                                        <AddButton onClick={() => handleAddSize(index)} />
                                    </li>}
                                    {items.sizes.length === 0 && colorId[index] && <h4 className='d-flex gap-3 align-items-center justify-content-center w-100'>{update && items.sizes.length === 0 ? "No size found ! ," : ""}Add size for the product  <AddButton onClick={() => handleAddSize(index)} /></h4>}
                                </ul>
                                <div class="tab-content">
                                    {items.sizes.length === 0 && <div class={`tab-pane fade d-none gj-sizeContent${index} show active`} id={`sizeContent0`}></div>}
                                    {items.sizes && items.sizes.length > 0 && items.sizes.map((size, sizeIndex) =>
                                        <div class={`tab-pane fade gj-sizeContent${index} ${sizeIndex === 0 ? "show active" : ""}`} id={`sizeContent${index}${sizeIndex + 1}`}>
                                            <Box component={"form"} onSubmit={(event) => handleSize(event, index, sizeIndex)}>
                                                <HDiv className={"py-3 gap-3 flex-wrap align-items-center "}>
                                                    <InputGroup value={size.skuTypeName} className={"col-xl-3 col-lg-3 col-md-5 col-sm-12 col-12 flex-grow-1"} onChange={(event) => handleSizeChange(event, index, sizeIndex)} enabled={size.btnDisabled} label="Sku-Type Name*" name="skuTypeName" type="text" placeHolder="Enter name" required />
                                                    <InputGroup value={size.skuTypeValue} className={"col-xl-3 col-lg-3 col-md-5 col-sm-12 col-12 flex-grow-1"} onChange={(event) => handleSizeChange(event, index, sizeIndex)} enabled={size.btnDisabled} label="Sku-Type Value*" name="skuTypeValue" type="text" placeHolder="Enter value" required />
                                                    <InputGroup value={size.skuValue} className={"col-xl-3 col-lg-3 col-md-5 col-sm-12 col-12 flex-grow-1"} onChange={(event) => handleSizeChange(event, index, sizeIndex)} enabled={size.btnDisabled} label="Stock*" name="skuValue" type="text" placeHolder="Enter value" required />
                                                    <InputGroup value={size.isPrice} className={"col-xl-3 col-lg-3 col-md-5 col-sm-12 col-12 flex-grow-1"} onChange={(event) => handleSizeChange(event, index, sizeIndex)} enabled={size.btnDisabled} label="New Price*" name="isPrice" type="text" placeHolder="Enter New Price" required />
                                                    <InputGroup value={size.wasPrice} className={"col-xl-3 col-lg-3 col-md-5 col-sm-12 col-12 flex-grow-1"} onChange={(event) => handleSizeChange(event, index, sizeIndex)} enabled={size.btnDisabled} label="Old Price*" name="wasPrice" type="text" placeHolder="Enter Old Price" required />
                                                    <SelectGroup value={size.discountType} className={"col-xl-3 col-lg-3 col-md-5 col-sm-12 col-12 flex-grow-1"} list={discountType} onChange={(event) => handleSizeChange(event, index, sizeIndex)} enabled={size.btnDisabled} label="Discount Type*" name="discountType" placeHolder="Discount Type" required />
                                                    <InputGroup value={size.discount} className={"col-xl-3 col-lg-3 col-md-5 col-sm-12 col-12"} onChange={(event) => handleSizeChange(event, index, sizeIndex)} enabled={size.btnDisabled} label="Discount*" name="discount" type="text" placeHolder="Enter Discount" required />
                                                    <label className='d-flex align-items-center gap-2'>
                                                        <input type='checkbox' name='preOrder' onChange={(event) => handleSizeChange(event, index, sizeIndex)} checked={size.preOrder} disabled={!size.btnDisabled} value={size.preOrder} /> Pre-Order
                                                    </label>
                                                   {size.availableStocks && <p className='fw-bolder ms-3 text-dark'>Available Stocks : {size.availableStocks}</p>}
                                                </HDiv>
                                                <HDiv className={"justify-content-end"}>
                                                {size.skuTypeId !== "" && <Button type="button" disabled={size.btnDisabled} onClick={()=>handleSizeDisabledBtn(index,sizeIndex)}>Edit</Button>}
                                                    <Button type="submit" disabled={!size.btnDisabled}>

                                                        {size.skuTypeId !== "" ? "Update" : "Save"}
                                                    </Button>
                                                </HDiv>
                                            </Box>
                                            <ul class="nav nav-tabs align-items-center gap-2">
                                                <li class="nav-item nav-color align-items-center  ">
                                                    <a class="nav-link active " id={`attributeTab0`} data-bs-toggle="tab" href={`#attributeContent0`}>Attributes</a>
                                                </li>

                                                {items.attributes.length !== 0 && <li class="nav-item">
                                                    <AddButton onClick={() => { handleAddAttribute(index, sizeIndex) }} />
                                                </li>}
                                                {items.attributes.length === 0 && <h4 className='d-flex gap-3 align-items-center justify-content-center w-100'> {update ? "No Attribute found !," : ""}Add Attribute for the product  <AddButton onClick={() => handleAddAttribute(index, sizeIndex)} /></h4>}
                                            </ul>
                                            <div class="tab-content p-2">
                                                {/* {size.attributes.length === 0 && <div class="tab-pane fade d-none gj-attributeContent show active" id={`attributeContent0`}></div>} */}

                                                <div class={`tab-pane fade show active`} id={`attributeContent0`}>
                                                    {items.attributes && items.attributes.length > 0 && items.attributes.map((attribute, attributeIndex) =>
                                                        <Box component={"form"} onSubmit={(event) => handleSaveAttribute(event, index, sizeIndex, attributeIndex)}>
                                                            <HDiv className={"gap-3 flex-wrap align-items-center "}>
                                                                <InputGroup value={attribute.label} className={"col-xl-3 col-lg-3 col-md-5 col-sm-12 col-12 flex-grow-1"} enabled={attribute.disabled} onChange={(event) => handleAttributeChange(event, index, sizeIndex, attributeIndex)} label="Attribute Name*" name="label" type="text" placeHolder="Enter Name" required />
                                                                <InputGroup value={attribute.attValue} className={"col-xl-3 col-lg-3 col-md-5 col-sm-12 col-12 flex-grow-1"} enabled={attribute.disabled} onChange={(event) => handleAttributeChange(event, index, sizeIndex, attributeIndex)} label="Attribute Value*" name="attValue" type="text" placeHolder="Enter Value" required />
                                                                <DeleteButton onClick={() => { attribute.attributeId !== "" ? handleDeleteAttribute(index, sizeIndex, attributeIndex, attribute.attributeId) : handleAttributeDelete(index, sizeIndex, attributeIndex) }} />
                                                                {!attribute.disabled ?
                                                                    <Button type='button' onClick={(event) => { event.preventDefault(); handleDisableInput(index, sizeIndex, attributeIndex) }}><img src='./assets/images/editButton.svg' width={"20px"} height={"20px"} alt='edit' /></Button> :
                                                                    <Button type="submit">
                                                                        <img src='./assets/images/saveButton.svg ' width={"20px"} height={"20px"} alt='save' />
                                                                    </Button>}
                                                            </HDiv>
                                                        </Box>)}
                                                </div>
                                            </div>
                                        </div>

                                    )}
                                </div>


                            </div>
                        )}
                    </div>

                    {ProductColor.length === 0 ? productId !== "" ?
                        <h4 className='d-flex gap-3 align-items-center justify-content-center textInfo'>{update ? "No color found ! ," : ""} Add color for the product  <AddButton onClick={() => { handleAdd() }} /></h4> : <h4 className='d-flex gap-3 align-items-center justify-content-center textInfo'>Please create the product</h4> : ""
                    }

                </div>


            </div>
            {apiInProgress && <ProcessLoader />}
        </>
    );
}