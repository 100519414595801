import { 
    getProductPriceBycolorProductApiUrl,
    getProductSkutypeBycolorProductApiUrl,
    getProductSkutypesbyColorProductApiUrl,
    getSaveProductSkutypebyColorProductApiUrl
 } from "./ApiUrls";
import { get, Delete, Post, Put } from "./Service";


export const getProducSkuTypesbyColorProduct = async (tenantId, productId,colorId) => {
    const response = await get(getProductSkutypesbyColorProductApiUrl(tenantId, productId,colorId));
    if(!response) {
        return []
    }
    return response;
};

export const getProductSkuTypebyColorProduct = async (tenantId, productId,colorId) => {
    const response = await get(getProductSkutypeBycolorProductApiUrl(tenantId, productId,colorId));
    if(!response) {
        return []
    }
    return response;
};

export const saveProductSkuTypebyColorProduct = async (tenantId, productId,colorId, data) => {
    const response = await Post(getSaveProductSkutypebyColorProductApiUrl(tenantId, productId,colorId), data);
    return response;
};

export const updateProductSkuTypebyColorProduct = async (tenantId, productId,colorId, data) => {
    const response = await Put(getSaveProductSkutypebyColorProductApiUrl(tenantId, productId,colorId), data);
    return response;
};

export const deleteProductSkuTypebyColorProduct = async (tenantId, productId, key) => {
    const response = await Delete(getProductSkutypeBycolorProductApiUrl(tenantId, productId, key));
    return response;
};

export const deleteProductSkuPriceByColorProduct = async(tenantId, productId,colorId,pSkuTypeId,pSkuId,priceId) =>{
    const response = await Delete(getProductPriceBycolorProductApiUrl(tenantId, productId,colorId,pSkuTypeId,pSkuId,priceId));    
    return response;
}